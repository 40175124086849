<div class="component-container">
  <div class="header">
    <div class="title">{{ TITLE_TEXT }}</div>
    <div class="subtitle">{{ SUBTITLE_TEXT }}</div>
  </div>
  <form class="subscribe-form" [formGroup]="subscriptionForm">
    <mat-form-field color="accent" hideRequiredMarker="true">
      <input  class="first-name-input" matInput formControlName="firstName" color="accent" type="text">
      <mat-icon class="input-icon" matPrefix>face</mat-icon>
      <mat-label class="input-placeholder">{{ FIRST_NAME_FIELD_VALUE }}</mat-label>
      @if (firstName.invalid && (firstName.dirty || firstName.touched)) {
        <mat-error>{{ firstNameErrorMessage }}</mat-error>
      }
    </mat-form-field>
    <mat-form-field color="accent" hideRequiredMarker="true">
      <input class="email-input" matInput formControlName="email" type="email" color="accent">
      <mat-icon class="input-icon" matPrefix>email</mat-icon>
      <mat-label class="input-placeholder">{{ EMAIL_FIELD_VALUE }}</mat-label>
      @if (email.invalid && (email.dirty || email.touched)) {
        <mat-error>{{ emailErrorMessage }}</mat-error>
      }
    </mat-form-field>
  </form>

  <div class="actions-container">
    @if (!(processSubscriptionFormProcessing$ | async) && !(userSubmittedForm$ | async)) {
      <button class="submit-button" mat-raised-button color="primary" [disabled]="(subscriptionForm.invalid && (subscriptionForm.dirty || subscriptionForm.touched)) || !$subscriptionFormSubmissionAllowed() || (userSubmittedForm$ | async)" (click)="onProcessSubscriptionForm()">
        {{ SUBMIT_BUTTON_VALUE }}
      </button>
      <button class="cancel-button" mat-stroked-button (click)="onCancel()">
        {{ NO_THANKS_BUTTON_VALUE }}
      </button>
    }

    @if (processSubscriptionFormProcessing$ | async) {
      <button class="submit-button" disabled>
        <app-processing-spinner [$spinnerDiameter]="32" [$spinnerMessage]="EMPTY_SPINNER_MESSAGE" [$whiteSpinner]="true"></app-processing-spinner>
      </button>
    }

    @if (userSubmittedForm$ | async) {
      <button class="submit-button" disabled>
        {{ 'Check your inbox!' }}
      </button>
      <button class="cancel-button" mat-stroked-button (click)="onCancel()">
        {{ CLOSE_BUTTON_VALUE }}
      </button>
    }
  </div>
  
</div>