<div class="app-content">
  <mat-sidenav-container>
    <mat-sidenav #sidenav mode="over" [fixedInViewport]="true" position="end" autoFocus="false" [opened]="uiService.$sideNavVisible()">
      <app-sidenav></app-sidenav>
    </mat-sidenav>
    <mat-sidenav-content>
      <div class="header-and-router-outlet">
        <app-header></app-header>
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>